import api from '@src/utils/api';

export const fetchRatingBoards = ({
  pageNum = 1,
  pageSize = 9,
  gameId = null,
  organization = null,
}) => {
  return api.get('/rating/board', {
    params: {
      pageNum: pageNum - 1,
      pageSize,
      game: gameId,
      organization,
    },
  });
};

export const fetchRatingById = ({
  pageNum = 1,
  pageSize = 12,
  id,
}) => {
  return api.get(`/rating/${id}`, {
    params: {
      pageSize,
      pageNum: pageNum - 1,
    },
  });
};

export const fetchUserTeamsRatings = ({
  pageNum = 1,
  pageSize = 8,
  gameId = null,
  userHash,
}) => {
  return api.get(`/profile/${userHash}/rating/teams`, {
    params: {
      pageSize,
      pageNum: pageNum - 1,
      game: gameId,
    },
  });
};

export const fetchUserPlayerRatings = ({
  pageNum = 1,
  pageSize = 8,
  gameId = null,
  userHash,
}) => {
  return api.get(`/profile/${userHash}/rating/player`, {
    params: {
      pageSize,
      pageNum: pageNum - 1,
      game: gameId,
    },
  });
};
